














































import Vue from 'vue';
import { mapMutations, mapState, mapActions } from 'vuex';
import { XIcon, SearchIcon } from 'vue-feather-icons';
import RepositoryFactory from '@/services/RepositoryFactory';
import IBoxesRepository from '@/services/Repositories/Boxes/types';

const factory = new RepositoryFactory();

const queryItems = [
  {
    label: 'search-by-reboxNo',
    value: 'code',
  },
  {
    label: 'search-by-orderNo',
    value: 'boxShippings.order.orderNr',
  },
  {
    label: 'search-by-email',
    value: 'boxShippings.order.custEmail',
  },
  {
    label: 'search-by-city',
    value: 'boxShippings.order.delAddressCity',
  },
  {
    label: 'search-by-street',
    value: 'boxShippings.order.delAddressStreet',
  },
  {
    label: 'search-by-streetNo',
    value: 'boxShippings.order.delAddressStreetNr',
  },
  {
    label: 'search-by-zip',
    value: 'boxShippings.order.delAddressZip',
  },
];

export default Vue.extend({
  name: 'BoxesSearchBy',
  data() {
    return {
      openCard: false,
      queryItems,
      dialog: false,
      regularExpression: '/boxes/([a-zA-Z0-9]{16})/*',
      boxCodeFormat: '([a-zA-Z0-9]{16})/*',
      timer: 0,
    };
  },
  components: {
    XIcon,
    SearchIcon,
  },
  computed: {
    ...mapState('globals', ['searchBarInput', 'searchBy']),
    ...mapState('boxes', ['boxesQuery']),
    boxesRepository(): IBoxesRepository {
      return factory.get('boxes') as IBoxesRepository;
    },

    inputValue: {
      get(): string {
        return this.searchBarInput;
      },
      set(value: string): void {
        if (!this.openCard && value.length) {
          this.openCard = true;
        }
        if (value === '') {
          this.openCard = false;
        }
        this.SET_SEARCH_INPUT(value);
      },
    },
    storeSearchKey: {
      get(): string {
        return this.searchBy;
      },
      set(value: string): void {
        this.SET_SEARCHBY(value);
      },
    },
  },
  methods: {
    ...mapMutations('globals', ['SET_SEARCH_INPUT', 'SET_SEARCHBY', 'SET_QUERY_BASED_ON_INPUT']),
    ...mapMutations('boxes', ['SET_QUERY_BASED_ON_INPUT', 'CLEAR_QUERY']),
    ...mapActions('boxes', ['getBoxes']),
    clearSearch(): void {
      this.openCard = false;
      this.SET_SEARCH_INPUT('');
      this.submit();
    },
    closeCardOnClick(e: any): void {
      const element = e.target.closest('form');
      if (!element) {
        this.openCard = false;
      }
    },
    cardOnFocus(): void {
      if (!!this.searchBarInput && !this.openCard) {
        this.openCard = true;
      }
    },
    async submit(): Promise<void> {
      this.SET_QUERY_BASED_ON_INPUT({ key: this.searchBy, value: this.inputValue });
      await this.getBoxes();
      this.CLEAR_QUERY();
      this.openCard = false;
    },
  },
  watch: {
    openCard: {
      handler(value) {
        this.SET_SEARCHBY('');
        if (value) {
          document.addEventListener('click', this.closeCardOnClick);
        } else {
          document.removeEventListener('click', this.closeCardOnClick);
        }
      },
    },
    inputValue: {
      handler() {
        const REBOX_CODE_LENGTH = 16;
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          if (this.inputValue.length === REBOX_CODE_LENGTH) {
            const matches = this.inputValue.match(this.boxCodeFormat);
            if (matches) {
              return;
            }
          }
          if (this.inputValue.length > REBOX_CODE_LENGTH) {
            const match = this.inputValue.match(this.regularExpression);
            if (match) {
              this.inputValue = match[1]; // eslint-disable-line
            }
          }
        }, 100);
      },
    },
  },
  beforeDestroy() {
    document.removeEventListener('click', this.closeCardOnClick);
  },
});
