




























































import Vue from 'vue';
import { mapMutations } from 'vuex';

export default Vue.extend({
  name: 'BarcodeScannerPopup',
  data() {
    return {
      dialog: false,
      inputValueOrderNo: '',
      scanSearchInputDelay: 0,
    };
  },
  props:
    [
      'submit',
    ],
  methods: {
    ...mapMutations('globals', ['SET_SEARCH_INPUT', 'SET_SEARCHBY']),
    clearSearchEmit() {
      this.inputValueOrderNo = '';
      this.$emit('clearSearch');
    },
    inputValueEmit() {
      this.$emit('inputValue', this.inputValueOrderNo);
    },
  },
  watch: {
    inputValueOrderNo(newInputValue) {
      const startDelay = () => {
        this.scanSearchInputDelay = setTimeout(() => {
          this.$emit('startDelay');
          this.SET_SEARCHBY('orderNr');
          this.SET_SEARCH_INPUT(newInputValue);
          this.submit();
          this.dialog = false;
        }, 1000);
      };

      const stopDelay = () => {
        clearTimeout(this.scanSearchInputDelay);
      };

      if (newInputValue.length === 1 && this.dialog) {
        startDelay();
      }
      if (newInputValue.length >= 2 && this.dialog) {
        if (this.scanSearchInputDelay) {
          stopDelay();
        }
        startDelay();
      }
    },
  },
});
