



















































import Vue from 'vue';
import { mapMutations, mapState, mapGetters } from 'vuex';
import { XIcon, SearchIcon } from 'vue-feather-icons';
import BarcodeScannerPopup from '@/components/ui/BarcodeScannerPopup.vue';

const queryItems = [
  {
    label: 'search-by-orderid',
    value: 'orderNr',
  },
  {
    label: 'search-by-email',
    value: 'custEmail',
  },
  {
    label: 'search-by-name',
    value: 'custName',
  },
  {
    label: 'search-by-surname',
    value: 'custSurname',
  },
];

type FilterBy = { [index: string]: string };

export default Vue.extend({
  name: 'OrdersSearchBy',
  data() {
    return {
      openCard: false,
      queryItems,
      dialog: false,
    };
  },
  components: {
    BarcodeScannerPopup,
    XIcon,
    SearchIcon,
  },
  computed: {
    ...mapState('globals', ['searchBarInput', 'searchBy']),
    ...mapGetters('orders', ['getFilterby']),

    filterBy(): FilterBy {
      return { [this.storeSearchKey]: this.inputValue };
    },
    inputValue: {
      get(): string {
        return this.searchBarInput;
      },
      set(value: string): void {
        if (!this.openCard && value.length) {
          this.openCard = true;
        }
        if (value === '') {
          this.openCard = false;
        }
        this.SET_SEARCH_INPUT(value);
      },
    },
    storeSearchKey: {
      get(): string {
        return this.searchBy || 'orderNr';
      },
      set(value: string): void {
        this.SET_SEARCHBY(value);
      },
    },
  },
  methods: {
    ...mapMutations('globals', ['SET_SEARCH_INPUT', 'SET_SEARCHBY']),
    ...mapMutations('orders', ['SET_FILTERBY', 'SET_PAGE', 'CLEAR_ORDERS', 'SET_SEARCHCARD']),
    clearSearch(): void {
      this.openCard = false;
      this.SET_SEARCH_INPUT('');
      this.submit();
    },
    submit(): void {
      this.CLEAR_ORDERS();
      this.SET_PAGE();
      this.SET_FILTERBY(this.filterBy);
      this.openCard = false;
    },
    closeCardOnClick(e: any): void {
      const element = e.target.closest('form');
      if (!element) {
        this.openCard = false;
      }
    },
    cardOnFocus(): void {
      if (!!this.searchBarInput && !this.openCard) {
        this.openCard = true;
      }
    },
  },
  watch: {
    searchBy: {
      handler() {
        this.submit();
        this.openCard = false;
      },
    },
    openCard: {
      handler(value) {
        this.SET_SEARCHCARD(value);
        if (value) {
          document.addEventListener('click', this.closeCardOnClick);
        } else {
          document.removeEventListener('click', this.closeCardOnClick);
        }
      },
    },
  },
  beforeDestroy() {
    document.removeEventListener('click', this.closeCardOnClick);
  },
});
