









































































import Vue from 'vue';
import { mapState, mapMutations } from 'vuex';
import { XIcon, FilterIcon, SearchIcon, SettingsIcon, Trash2Icon } from 'vue-feather-icons';
import { EnumsActions, EnumsSecondaryIcons } from '@/store/modules/globals/types';
import OrdersSearchBy from '@/components/boxes/OrdersSearchBy.vue';
import BoxesSearchBy from '@/components/boxes/BoxesSearchBy.vue';

export default Vue.extend({
  name: 'TheHeader',
  data() {
    return {
      searchBar: false,
    };
  },
  components: {
    XIcon,
    FilterIcon,
    SearchIcon,
    SettingsIcon,
    OrdersSearchBy,
    BoxesSearchBy,
    Trash2Icon,
  },
  computed: {
    ...mapState('globals', [
      'title',
      'configuration',
      'icon',
      'action',
      'secondaryIcon',
      'searchBarInput',
      'filterDrawerIcon',
      'filterDrawer',
      'previousPage',
    ]),
    ...mapState('orders', ['deleteMode', 'deleteIcon']),
    ordersPage(): boolean {
      return this.$route.name === 'allOrders';
    },
    boxOverviewPage(): boolean {
      return this.$route.name === 'BoxOverview';
    },
    extensionPage(): boolean {
      const pages = ['allOrders', 'BoxOverview'];
      if (this.$route.name) {
        return pages.includes(this.$route.name);
      }
      return false;
    },
    checkIcons() {
      if (this.icon && this.secondaryIcon) {
        return 6;
      }
      if (this.icon && this.filterDrawerIcon) {
        return 6;
      }
      if (this.icon && !this.secondaryIcon && !this.filterDrawerIcon) {
        return 8;
      }
      return 8;
    },
  },
  methods: {
    ...mapMutations('globals', [
      'SET_ALERT_TITLE',
      'SET_ALERT_TEXT',
      'SET_ALERT_DIALOG',
      'SET_SEARCH_INPUT',
      'SET_FILTERDRAWER',
      'SET_SETTINGS_DRAWER',
    ]),
    ...mapMutations('orders', ['SET_DELETE_MODE']),
    handleButtonAction(): void | undefined {
      if (this.action === EnumsActions.processes) {
        this.$router.push({ name: 'index' });
      }
      if (this.action === EnumsActions.goBack) {
        if (this.previousPage.name) {
          this.$router.replace(this.previousPage);
        } else {
          this.$router.push({ name: 'index' });
        }
      }
      if (this.action === EnumsActions.close) {
        this.SET_ALERT_TITLE(this.$t('alerts.page-exit'));
        this.SET_ALERT_TEXT(this.$t('alerts.exit-page-confirmation'));
        this.SET_ALERT_DIALOG();
      }
    },
    handleSecondaryAction(): void {
      if (this.secondaryIcon === EnumsSecondaryIcons.search) {
        this.searchBar = true;
      }
    },
    switchDeleteModeOn(): void {
      // eslint-disable-next-line no-unused-expressions
      this.deleteMode ? this.SET_DELETE_MODE(false) : this.SET_DELETE_MODE(true);
    },
    handleSearchInputClearAction(): void {
      if (this.searchBarInput === '') {
        this.searchBar = false;
        return;
      }
      this.SET_SEARCH_INPUT('');
    },
  },
  watch: {
    ordersPage: {
      handler(val) {
        if (!val) {
          this.searchBar = false;
        }
      },
    },
    boxOverviewPage: {
      handler(val) {
        if (!val) {
          this.searchBar = false;
        }
      },
    },
  },
});
